import React, { useEffect, useState } from "react";
import { Row, Col, Select, Button, Switch, message, Modal } from "antd";
import PaymentModalCard from "../paymentCard/selectPayment";
import "./Landing.css";
import "./footer.css";
import styles from "./plan_cards.module.css";
import { CheckCircleFilled } from "@ant-design/icons";
import { connect } from "react-redux";
import APIManager from "../../APIManager";
import currentPlanIcon from "../asset/business/current-plan.svg";
import { withRouter, useHistory, useParams } from "react-router-dom";
import ellipseImg from "../asset/ellipse_gradient_right.png";
const Option = { Select };

const PlanCards = (props) => {
  const [isPremium, setIsPremium] = useState("");
  const [userCount, setUserCount] = useState(
    props?.userCount ? props?.userCount : 0
  );
  const history = useHistory();
  const [isSwitchToFreemium, setIsSwitchToFreemium] = useState(true);
  var ls = require("local-storage");
  useEffect(() => {
    APIManager.companyInfo().then((response) => {
      if (response.data.isSuccess) {
        setIsPremium(response.data.data.data.isPremium);
        setUserCount(response.data.data.data.UserCount);
        setIsSwitchToFreemium(response.data.data.data.isSwitchFreemium);
      }
    });
  }, [isSwitchToFreemium]);
  useEffect(() => {
    const params = {
      userCount: userCount,
      isMonthly: isMonthlyPlan,
    };
    APIManager.taxInfo(params).then((response) => {
      if (response?.data) {
        setTaxData(response?.data?.data[0]);
      }
    });
    const success = history?.location?.search?.split("=")?.[1];
    if (success === "true") {
      message.success("Premium plan successfully purchased!");
    }
    if (success === "false") {
      message.error(
        "Purchase failed!,Something went wrong please try again later."
      );
    }
  }, []);
  var ls = require("local-storage");
  const [PaymentModal, showPaymentModal] = useState(false);
  const [isMonthlyPlan, setisMonthlyPlan] = useState(true);
  const [switchPlanModal, setSwitchPlanModal] = useState(false);
  const [taxData, setTaxData] = useState({});
  ls.set("setisMonthlyPlan", isMonthlyPlan);
  const switchPlan = () => {
    setisMonthlyPlan(!isMonthlyPlan);
    window.localStorage.setItem("setisMonthlyPlan", isMonthlyPlan);
  };
  const token = ls.get("token");
  const renderPlanSection = () => {
    if (token) {
      showPaymentModal(true);
    } else {
      props.history.push("/login");
    }
  };
  const renderPlanSectionFree = () => {
    props.history.push("/signup");
  };
  const proceedToFreemium = () => {
    APIManager.switchToFreemium()
      .then((response) => {
        setIsSwitchToFreemium(true);
        message.success(
          "Your Plan will switch to freemium after current plan expires"
        );
      })
      .catch((err) => {
        message.error("Something went wrong please try again");
      })
      .finally(() => {
        setSwitchPlanModal(false);
      });
  };

  return (
    <>
      {PaymentModal ? (
        <PaymentModalCard
          show={PaymentModal}
          onHide={() => showPaymentModal(false)}
          isMonthlyPlan={isMonthlyPlan}
          userCount={userCount}
          taxData={taxData}
        />
      ) : (
        ""
      )}
      <Modal
        title="Switch Plan to Freemium"
        open={switchPlanModal}
        onCancel={() => setSwitchPlanModal(false)}
        className="switch-plan-modal"
        onOk={proceedToFreemium}
      >
        <p>Do you really want to switch to freemium Account !</p>
        <p>
          Your plan will switch to freemium after your current subscription
          expires !
        </p>
      </Modal>
      <Row className={styles.planCardsParents}>
        <Col sm={4} xs={0}></Col>

        <Col sm={7} xs={24} className={`${styles.planCard1}`}>
          {!isPremium && (
            <img
              className="current-img"
              src={process.env.PUBLIC_URL + "/current-plan.png"}
            />
          )}
          <div>
            <div className="plan-heading mt-5">
              <span className="plan-heading-sp">FREEMIUM PLAN</span>
            </div>
            <div className="plan-free  mt-5">FREE</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
              marginTop: "20px",
            }}
          >
            <Button
              className={`buy-now`}
              onClick={renderPlanSectionFree}
              disabled={isPremium}
            >
              Start for FREE
            </Button>
          </div>
          <div className="plan-points">
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                5 activate Services/Products/Jobs max
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Creation of the free company page{" "}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited Media{" "}
              </span>
            </div>

            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited No. of posts{" "}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of resumes{" "}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of responses
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited interest on products
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited interest for services
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of users
              </span>
            </div>
          </div>
        </Col>
        <Col sm={7} xs={24} className={styles.planCard2}>
          {isPremium && (
            <img
              className="current-img"
              src={process.env.PUBLIC_URL + "/current-plan.png"}
            />
          )}
          <div>
            <div className="plan-heading mt-5">
              {" "}
              <span className="plan-heading-sp">PREMIUM PLAN</span>
            </div>
            {isMonthlyPlan ? (
              <div className="plan-free mt-5">
                <span className="plan-dollar">$</span>
                <b>15</b>
                <span className="plan-month">/MO./USER</span>
              </div>
            ) : (
              <div className="plan-free mt-5">
                <span className="plan-dollar">$</span>
                <b>120</b>
                <span className="plan-month">/Yr.</span>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            {isPremium ? (
              <>
                {!isSwitchToFreemium ? (
                  <Button
                    className={`buy-now  ${styles.buyNowButton}`}
                    onClick={() => setSwitchPlanModal(true)}
                  >
                    Switch to Freemium
                  </Button>
                ) : (
                  <Button
                    className={`buy-now  ${styles.buyNowButton}`}
                    onClick={renderPlanSection}
                    disabled={isPremium}
                  >
                    Buy Now
                  </Button>
                )}
              </>
            ) : (
              <Button
                className={`buy-now`}
                onClick={renderPlanSection}
                disabled={isPremium}
              >
                Buy Now
              </Button>
            )}
          </div>
          <div className={`plan-toggle ${styles.planToggle}`}>
            Monthly&nbsp;&nbsp;
            <Switch
              defaultValue={isMonthlyPlan}
              onChange={switchPlan}
              disabled={isPremium}
            />
            &nbsp;&nbsp;Yearly
          </div>
          <div className="plan-points" style={{ marginTop: "-22px" }}>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited activate Services/Products/Jobs
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Creation of the free company page{" "}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited Media{" "}
              </span>
            </div>

            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited No. of posts{" "}
              </span>
            </div>

            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of resumes{" "}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of responses
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited interest on products
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited interest for services
              </span>
            </div>
            {/* <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of users
              </span>
            </div> */}
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited Job Offers
              </span>
            </div>
            {/* <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited Products or Services
              </span>
            </div> */}
            {/* <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">1 User</span>
            </div> */}
          </div>
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
    isloading: state.AuthReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(PlanCards));
